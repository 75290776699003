<template>
  <section v-if="!hasAccountCompanies" class="get-things-done">
    <h3>
      We're here to help get things done
    </h3>
    <p>
      Let us make starting and maintaining a business entity easier, so you can focus on your
      customers—the reason why you got into business in the first place.
    </p>
    <div class="benefits-container">
      <section class="benefits">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline_lock.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline_lock.png')"
            alt="Secure Client Account"
          >
        </picture>
        <h4>
          Secure Client Account
        </h4>
        <p>
          Your free online account is the ultimate resource—secure your company details,
          documents, and state registrations.
        </p>
      </section>
      <div class="benefits-divider" />
      <section class="benefits">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline_flag.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline_flag.png')"
            alt="Essential Business Services"
          >
        </picture>
        <h4>
          Essential Business Services
        </h4>
        <p>
          We're your one-stop shop for all the tools, products, and services you need to start
          and run a successful business.
        </p>
      </section>
      <div class="benefits-divider" />
      <section class="benefits">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline_clipboard.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline_clipboard.png')"
            alt="Simplify With DIY"
          >
        </picture>
        <h4>
          Simplify With DIY
        </h4>
        <p>
          Use our free tools to fill out the state forms and templates you need to file with
          local, state, or federal agencies.
        </p>
      </section>
    </div>
    <button
      class="primary"
      aria-label="get started button"
      @click="startNewBusiness"
    >
      Get Started
    </button>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StagelineNoCompanies',
  computed: {
    ...mapGetters('stageline', [
      'accountCompanies',
    ]),
    hasAccountCompanies() {
      return this.accountCompanies.length
    },
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'resetSlideProgress',
    ]),
    startNewBusiness() {
      this.resetSlideProgress()
      this.$router.push({ name: 'createCompany' })
    },
  },
}
</script>

<style scoped lang="scss">
// Palette
$black1: #231F20;
$gray2-light3: #E9E9E9;
$teal1: $ct-ui-color-10;
$teal2: $ct-ui-color-26;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-6, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

// Typography: Fonts
h3, h4, p {
  margin: 0;
}

h3 {
  @include typography(
    $font-size: 2.0em,
    $font-weight: $ct-ui-font-weight-7
  );
}

h4 {
  @include typography(
    $font-size: 1.5em,
    $font-weight: $ct-ui-font-weight-7
  );
}

p {
  @include typography();
}

// Buttons
// Buttons: Mixins
@mixin btn-hover($hover-color: $teal2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $hover-color) {
  @include typography($color: white);
  width: 100%;
  border: 0.0625em solid $teal1;
  border-radius: 0.3125em;
  padding: $padding;
  margin: 0;
  outline: 0;
  line-height: 1.1;

  &:hover {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.6em 1.875em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color);
}

// Buttons: Types
button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $teal2
  );
}

// Normalize
* {
  margin: 0;
  padding: 0;
}

// Component
.get-things-done {
  width: 100%;
  max-width: 56em;
  padding-top: 2.0em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  h3 {
    padding-bottom: 0.5em;
  }

  p {
    padding-bottom: 1.0em;
    text-align: center;
  }

  .benefits-container {
    padding: 2.0em 0;
    display: grid;
    grid-template-columns: 1fr auto 1fr auto 1fr;
    align-items: center;

    .benefits {
      width: 100%;
      max-width: 20em;
      height: 100%;
      max-height: 23.125em;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      picture {
        img {
          height: 6.25em;
        }
      }

      h4 {
        padding: 0.4em 1.0em;
        text-align: center;
        min-height: 4.75em;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        padding:0 1.0em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    .benefits-divider {
      align-self: center;
      width: 1px;
      height: 67%;
      background: $gray2-light3;
      margin: 0 1.0em;
    }
  }

  .primary {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  h3 {
    @include typography(
      $font-size: 1.5em,
      $font-weight: $ct-ui-font-weight-7
    );
    text-align: center;
  }

  .get-things-done {
    padding-top: 4.0em;

    .benefits-container {
      grid-template-columns: 1fr;

      .benefits-divider {
        justify-self: center;
        width: 67%;
        height: 1px;
        margin: 2.0em 0;
      }
    }

    .primary {
      display: block;
    }
  }
}
</style>
