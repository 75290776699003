var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasAccountCompanies
    ? _c("section", { staticClass: "get-things-done" }, [
        _c("h3", [_vm._v("\n    We're here to help get things done\n  ")]),
        _c("p", [
          _vm._v(
            "\n    Let us make starting and maintaining a business entity easier, so you can focus on your\n    customers—the reason why you got into business in the first place.\n  "
          ),
        ]),
        _c("div", { staticClass: "benefits-container" }, [
          _c("section", { staticClass: "benefits" }, [
            _c("picture", [
              _c("source", {
                attrs: {
                  srcset: require("@images/illustrations/stageline/stageline_lock.webp"),
                  type: "image/webp",
                },
              }),
              _c("img", {
                attrs: {
                  src: require("@images/illustrations/stageline/stageline_lock.png"),
                  alt: "Secure Client Account",
                },
              }),
            ]),
            _c("h4", [_vm._v("\n        Secure Client Account\n      ")]),
            _c("p", [
              _vm._v(
                "\n        Your free online account is the ultimate resource—secure your company details,\n        documents, and state registrations.\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "benefits-divider" }),
          _c("section", { staticClass: "benefits" }, [
            _c("picture", [
              _c("source", {
                attrs: {
                  srcset: require("@images/illustrations/stageline/stageline_flag.webp"),
                  type: "image/webp",
                },
              }),
              _c("img", {
                attrs: {
                  src: require("@images/illustrations/stageline/stageline_flag.png"),
                  alt: "Essential Business Services",
                },
              }),
            ]),
            _c("h4", [_vm._v("\n        Essential Business Services\n      ")]),
            _c("p", [
              _vm._v(
                "\n        We're your one-stop shop for all the tools, products, and services you need to start\n        and run a successful business.\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "benefits-divider" }),
          _c("section", { staticClass: "benefits" }, [
            _c("picture", [
              _c("source", {
                attrs: {
                  srcset: require("@images/illustrations/stageline/stageline_clipboard.webp"),
                  type: "image/webp",
                },
              }),
              _c("img", {
                attrs: {
                  src: require("@images/illustrations/stageline/stageline_clipboard.png"),
                  alt: "Simplify With DIY",
                },
              }),
            ]),
            _c("h4", [_vm._v("\n        Simplify With DIY\n      ")]),
            _c("p", [
              _vm._v(
                "\n        Use our free tools to fill out the state forms and templates you need to file with\n        local, state, or federal agencies.\n      "
              ),
            ]),
          ]),
        ]),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { "aria-label": "get started button" },
            on: { click: _vm.startNewBusiness },
          },
          [_vm._v("\n    Get Started\n  ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }